import Link from "next/link";
import Image from "next/image";

import PureskillsLogo from "assets/pureskills-hybrid.svg";
import PureskillsLogoBeta from "assets/temp_logo_beta.png";

import style from "./unlogged-header.module.scss";

const renderLogo = (env: string = "production") => {
  if (env === "production") {
    return <PureskillsLogo style={{ height: "21px" }} />;
  }
  return (
    <div
      style={{
        width: "150px",
        height: "21px",
      }}
    >
      <Image src={PureskillsLogoBeta} alt="Logo Beta" />
    </div>
  );
};

const UnloggedHeader = () => {
  return (
    <div className={style["unlogged-header"]}>
      <Link href="/" passHref>
        <a className={style["inline-block"]}>
          {renderLogo(process?.env?.NEXT_PUBLIC_ENV as string)}
        </a>
      </Link>
    </div>
  );
};

export default UnloggedHeader;
